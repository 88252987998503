import styleParams from '../WeeklyTimetable/stylesParams';
import { dailyAgendaSettingsParamsDefinitions } from './settingsParams';
import { CustomCssVarsFn } from '@wix/yoshi-flow-editor';
import { settingsParamsDefinitions } from '../BookingCalendar/settingsParams';
import { weeklyTimetableSettingsParamsDefinitions } from '../WeeklyTimetable/settingsParams';
import { generateCustomCssVars } from '../BookingCalendar/stylesParamsUtils';

export default styleParams;
export const customCssVars: CustomCssVarsFn = (args) =>
  generateCustomCssVars(args, {
    ...settingsParamsDefinitions,
    ...weeklyTimetableSettingsParamsDefinitions,
    ...dailyAgendaSettingsParamsDefinitions,
  });
